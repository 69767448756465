import {useI18n} from "vue-i18n"
import {useAppUtils} from '@/composables/useAppUtils'
import {useLocale} from "@/composables/useLocale"

const FILES_TYPES = {
  logo: ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'],
  favicon: ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/x-icon', 'image/vnd.microsoft.icon'],
}

const FILES_SIZES = {
  logo: 2 * 1024 * 1024,
  favicon: 1 * 1024 * 1024,
}

export type ICustomItemType = 'logo' | 'favicon'
export type ICustomLogoType = 'main' | 'login'

export function useCustomLogo () {

  const { t } = useI18n()
  const { f } = useLocale()
  const {
    showError,
    showWarning,
  } = useAppUtils()

  const getFileMaxSize = (itemType: ICustomItemType) => FILES_SIZES[itemType] //todo: receive from server
  const getFileTypes = (itemType: ICustomItemType) => FILES_TYPES[itemType]

  const alertFileSizeLimit = (fileName) => {
    showWarning(f('File "%s" is too large', `<b>${fileName}</b>`))
  }

  const alertWrongFileType = () => {
    showError(t('wrong file extension'), {id: 'wrong file extension'})
  }

  const isAllowItemUpload = (file, itemType: ICustomItemType) => {
    let isAllow = true
    const fileType = file.type || ''
    if (!getFileTypes(itemType).includes(fileType)) {
      alertWrongFileType()
      isAllow = false
    }

    if (file.size && file.size > getFileMaxSize(itemType)) {
      alertFileSizeLimit(file.name)
      isAllow = false
    }

    return isAllow
  }

  const getDefaultLogo = (logoType: ICustomLogoType) => {
    const antiCache = Date.now()
    const logo1x = (logoType === 'main' ? '/img/logo_v2.png' : '/img/login/login_top.png') + '?t=' + antiCache
    const logo2x = (logoType === 'main' ? '/img/logo2x_v2.png' : '/img/login/login_top2x.png') + '?t=' + antiCache
    return {
      src: logo1x,
      srcset: logo1x + ', ' + logo2x + ' 2x'
    }
  }

  const getCustomLogo = (logoType: ICustomLogoType) => {
    const antiCache = Date.now()
    const logo1x = (logoType === 'main' ? '/custom_img.php?file=logo_v2.png' : '/custom_img.php?file=login_top.png') + '&t=' + antiCache
    const logo2x = (logoType === 'main' ? '/custom_img.php?file=logo2x_v2.png' : '/custom_img.php?file=login_top2x.png') + '&t=' + antiCache
    return {
      src: logo1x,
      srcset: logo1x + ', ' + logo2x + ' 2x'
    }
  }

  const getCustomLogoPreview = (logoType: ICustomLogoType) => {
    const antiCache = Date.now()
    const logo1x = (logoType === 'main' ? '/custom_img.php?file=logo_preview.png' : '/custom_img.php?file=login_preview.png') + '&t=' + antiCache
    //logo2x = (logoType === 'main' ? '/custom_img.php?file=logo2x.png' : '/custom_img.php?file=login_top2x.png') + '&t=' + antiCache;
    return {
      src: logo1x,
      srcset: logo1x //+ ', ' + logo2x + ' 2x'
    }
  }

  return {
    getDefaultLogo,
    getCustomLogo,
    getCustomLogoPreview,
    isAllowItemUpload,
  }
}

